import React, {useEffect, useState} from "react";

import './billettknapp.style.scss';


const Billettknapp = ({url, visible}) =>{

    return (
        <div className='knapp-wrapper'
        style={{opacity:`${visible ? '1' : '0' }`}}>
            <a href={url}>
                <div className='knapp'>
                    KJØP<br/>BILLETT!
                </div>
            </a>
        </div>
    )
}

export default Billettknapp;