import React from "react";
import styled from 'styled-components'

const DotElement = styled.span`
    display:inline-block;
    width: 1.3vw;
    height: 1.3vw;
    min-width:14px;
    min-height:14px;
    margin-top: 0.2vw;
    border-radius: 100%;
    border: 2px solid black;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: start;
    justify-self: end;
`;

const DotOutline = () =>{
    return <DotElement/>
}

export default DotOutline;