import React from "react";
import {
  Routes,
  Route,
  useParams
} from "react-router-dom";

import './App.scss';

import Frontpage from './pages/frontpage.page';
import ArtistPage from "./pages/ArtistPage.page";

function App() {

  return (
    <div>
      <Routes>
          <Route exact path="/2022/" render={() => {window.location.href="/2022/"}} />
          <Route path="/*" element={<Frontpage />} />
      </Routes>
    </div>
  );
}

export default App;
