import React, {useEffect, useState, useRef} from "react";

import {BiArrowBack as Arrow} from 'react-icons/bi'

import './accordion.styles.scss';

const Accordion = ({title, children}) =>{
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef();
    return (
        <div className='accordion'>
            <div className={`header-wrapper ${isOpen ? 'accordion-open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                <div className='header'>
                    <div className={`${isOpen ? 'header-open' : 'header-closed'}`}>
                        {title}
                    </div>
                    {/* <div className={`header-arrow ${isOpen ? 'header-arrow-open' : ''}`}>
                        <Arrow/>
                    </div> */}
                </div>
            </div>
            <div
             
             ref={contentRef}
             style={
                isOpen
                  ? { 
                      height: contentRef.current.scrollHeight,
                      opacity: 1
                     }
                  : { 
                      marginTop: "0px",
                      height: "0px",
                      opacity: 0
                    }
              }
             className={`content-wrapper ${isOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default Accordion